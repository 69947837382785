import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import AuthService from "../../api/ApiRest";
import Logo from '../../assets/lazona.png'
import '../../index.css'
import QRCode from 'react-qr-code';

const Socio = () => {
    const [id]=useState(useParams().id);
    const [url]=useState(`https://socios.clublazona.cl/socio/${id}`);
    const [nombre,setNombre]=useState('');
    const [rut, setRut] = useState('');
    const [vigencia, setVigencia] = useState('');
    const [esValido,setEsValido]=useState(false);
    
    useEffect(()=>{
        const esBase=()=>{
            const expresionRegular=/^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
            if( expresionRegular.test(id)){
                setEsValido(true);
            }else{
                setEsValido(false);
            } 
        }
        esBase();
        const participantesData=async()=>{
            if(esValido===true){
                const response= await AuthService.getUsuarioId(atob(id));
                const options = { style: 'decimal', maximumFractionDigits: 2 }; // Opciones de formateo

                setNombre(response.data.body[0].nombres+' '+response.data.body[0].primer_apellido+' '+response.data.body[0].segundo_apellido);
                setRut(response.data.body[0].dv?response.data.body[0].run.toLocaleString('es-ES', options)+'-'+response.data.body[0].dv:response.data.body[0].run.toLocaleString('es-ES', options));
                setVigencia(response.data.body[0].vigencia.substring(0,10));
            }
        }
        participantesData();
  
  },[esValido])

  return (
    <div className="custom-presentation-card">
        <div className="custom-logo-container">
          <img src={Logo} alt="Club La Zona" className="custom-logo" />
        </div>
        <div className="custom-presentation-card-body">
            <span>NOMBRES: {nombre}</span>
            <span>RUN: {rut}</span>
        </div>
        <div className="custom-qr-code">
          <QRCode value={url}  size={100}/>
        </div>
        <div className="custom-presentation-card-footer">
            <span>VIGENCIA: {vigencia}</span>
        </div>
    </div>
  )
}

export default Socio