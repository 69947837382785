import axios from "./Axios"

const login = (username, password) => {
  return axios
    .post("auth/login", {'usuario':username,'password':password})
    .then((response) => {
      if (response.data.body) {
        localStorage.setItem("Token", response.data.body);
      }
      return response;
    })
    .catch((error)=>{
        return error.response.data;
      })
};

const token=localStorage.getItem("Token");

const logout = () => {
  localStorage.removeItem("Token");
};

const postUsuario = (data) => {
    return axios
      .post("usuarios", data, {
        headers: {
          'Authorization': `Bearer ${token}`
        }})
      .then((response) => {
        return response.data;
      })
      .catch((error)=>{
        return error.response.data;
      })
  };
  const putUsuario = (data) => {
    return axios
      .put("usuarios", data, {
        headers: {
          'Authorization': `Bearer ${token}`
        }})
      .then((response) => {
        return response.data;
      })
      .catch((error)=>{
        return error.response.data;
      })
  };
  const getUsuarios = () => {
    return axios
      .get('usuarios', {
        headers: {
          'Authorization': `Bearer ${token}`
        }})
      .then((response) => {
        return response;
      })  
      .catch((error)=>{
        return error.response;
      })
  };
  const getUsuarioId = (id) => {
    return axios
      .get(`usuarios/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }})
      .then((response) => {
        return response;
      })  
      .catch((error)=>{
        return error;
      })
  };
  const postUsuariosNombres = (nombres,primer_apellido,segundo_apellido) => {
    return axios
      .post(`usuarios/nombres?nombres=${nombres}&primer_apellido=${primer_apellido}&segundo_apellido=${segundo_apellido}`)
      .then((response) => {
        return response;
      })  
      .catch((error)=>{
        return error;
      })
  };
  

export default {
  login,
  logout,
  postUsuario,
  putUsuario,
  getUsuarios,
  getUsuarioId,
  postUsuariosNombres,
};