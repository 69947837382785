import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import TarjetaEvento from './TarjetaEvento';
import '../styles/principal.css'

const Eventos = () => {
    const products = [
        {
          evento: 'Evento 1',
          fecha:  '01 de Agosto 2024',
          lugar: 'MOVISTAR ARENA',
          image: 'https://static.ptocdn.net/images/eventos/rec078_calugalistado.jpg'
        },
        {
            evento: 'Evento 2',
            fecha:  '01 de Septiembre 2024',
            lugar: 'ESTADIO MONUMENTAL',
            image: 'https://static.ptocdn.net/images/eventos/biz269_calugalistado.jpg'
        },
        {
            evento: 'Evento 3',
            fecha:  '01 de Octubre 2024',
            lugar: 'MOVISTAR ARENA',
            image: 'https://static.ptocdn.net/images/eventos/sm0153_calugalistado.jpg'
          },
          {
            evento: 'Evento 4',
            fecha:  '01 de Diciembre 2024',
            lugar: 'MOVISTAR ARENA',
            image: 'https://static.ptocdn.net/images/eventos/sou028_calugalistado.jpg'
          },
          {
            evento: 'Evento 5',
            fecha:  '01 de Diciembre 2024',
            lugar: 'MOVISTAR ARENA',
            image: 'https://static.ptocdn.net/images/eventos/lot156_calugalistado.jpg'
          },
          {
            evento: 'Evento 6',
            fecha:  '01 de Diciembre 2024',
            lugar: 'MOVISTAR ARENA',
            image: 'https://static.ptocdn.net/images/eventos/jts072_calugalistado.jpg'
          },
        // Añade más productos según sea necesario
      ];
  return (
        <Container>
            <Row>
            {products.map((product, index) => (
                <Col key={index} sm={8} md={4} lg={3}>
                <TarjetaEvento product={product} />
                </Col>
            ))}
            </Row>
        </Container>

  )
}

export default Eventos