import React from 'react'
import ProductCard from './TarjetaDescuento';
import { Col, Container, Row } from 'react-bootstrap';
import '../styles/principal.css'


const Descuentos = () => {

    const products = [
        {
          name: 'Evento 1',
          originalPrice: 100,
          discountedPrice: 75,
          image: 'https://clublazona.cl/wp-content/uploads/2024/03/IG.png'
        },
        {
          name: 'Evento 2',
          originalPrice: 200,
          discountedPrice: 150,
          image: 'https://clublazona.cl/wp-content/uploads/2024/03/IG.png'
        },
        {
            name: 'Evento 3',
            originalPrice: 200,
            discountedPrice: 150,
            image: 'https://clublazona.cl/wp-content/uploads/2024/03/IG.png'
          },
          {
            name: 'Evento 4',
            originalPrice: 200,
            discountedPrice: 150,
            image: 'https://clublazona.cl/wp-content/uploads/2024/03/IG.png'
          },
          {
            name: 'Evento 5',
            originalPrice: 200,
            discountedPrice: 150,
            image: 'https://clublazona.cl/wp-content/uploads/2024/03/IG.png'
          },
          {
            name: 'Evento 6',
            originalPrice: 200,
            discountedPrice: 150,
            image: 'https://clublazona.cl/wp-content/uploads/2024/03/IG.png'
          },
        // Añade más productos según sea necesario
      ];
  return (
    <div className="background-image">
        <Container>
            <Row>
            {products.map((product, index) => (
                <Col key={index} sm={12} md={6} lg={4}>
                <ProductCard product={product} />
                </Col>
            ))}
            </Row>
        </Container>
    </div>
  )
}

export default Descuentos
