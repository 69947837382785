import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import Foto from '../../assets/IMG-20240524-WA0012.jpg'
import { useContext } from 'react';
import { AuthContext } from '../../auth/authContext';
import AuthService from "../../api/ApiRest";
import '../styles/principal.css'
import QRCode from 'react-qr-code';
import Logo from '../../assets/lazona.png'


const Perfil = () => {
    const {user, dispatch}=useContext(AuthContext);
    const [nombre,setNombre]=useState('');
    const [rut, setRut] = useState('');
    const [vigencia, setVigencia] = useState('');
    const [correo, setCorreo] = useState('');
    const [id, setId] = useState('');
    useEffect(()=>{
        const participantesData=async()=>{

            const response= await AuthService.getUsuarioId(user.id);
            const options = { style: 'decimal', maximumFractionDigits: 2 }; // Opciones de formateo

            setNombre(response.data.body[0].nombres+' '+response.data.body[0].primer_apellido+' '+response.data.body[0].segundo_apellido);
            setRut(response.data.body[0].dv?response.data.body[0].run.toLocaleString('es-ES', options)+'-'+response.data.body[0].dv:response.data.body[0].run.toLocaleString('es-ES', options));
            setVigencia(response.data.body[0].vigencia.substring(0,10));
            setCorreo(response.data.body[0].correo_electronico);
            setId(response.data.body[0].id);
            
        }
        participantesData();
  
  },[])

  return (
    <div className="App">
        <Container className="mt-2">
            <Row className="justify-content-md-center">
                <Col md="8">
                <Card className='tarjeta-perfil'>
                    <Card.Header as="h5">Perfil de Socio</Card.Header>
                    <Card.Body>
                    <Row>
                        <Col md="4">
                        <img
                            src={Foto}
                            alt="User Avatar"
                            className="img-fluid rounded-circle mb-3"
                        />
                        </Col>
                        <Col md="8"  className='mt-5'>
                        <h4>{nombre}</h4>
                        <p><strong>Email: </strong>{correo}</p>
                        <p><strong>Run: </strong>{rut}</p>
                        <p><strong>Vigencia: </strong>{vigencia}</p>
                        <Button className='custom-button'>Editar Perfil</Button>
                        </Col>
                    </Row>
                    </Card.Body>
                </Card>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Perfil