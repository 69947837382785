import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Navbar } from '../ui/Navbar';
import { Sidebar } from '../ui/Sidebar';
import LoginScreen from '../login/Login'
import Dashboard from '../dashboard/Dashboard';
import '../styles/principal.css'; // Importa tus estilos aquí
import Perfil from '../perfil/Perfil';
import Descuentos from '../descuentos/Descuentos';
import Eventos from '../eventos/Eventos';




export const DashboardRoutes = () => {
  return (
    <div className="dashboard-container d-flex">
      <Navbar/>
      <div className="main-content flex-grow-1 p-3">
        <Routes>
            <Route path="/login" element={<LoginScreen/>}/>
            <Route path="/perfil" element={<Perfil/>}/>
            <Route path="/descuentos" element={<Descuentos/>}/>
            <Route path="/eventos" element={<Eventos/>}/>
            <Route path="/" element={<Dashboard/>}/>
        </Routes>
        </div>
    </div>
  )
}