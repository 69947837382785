import { HashRouter, Link, NavLink, useNavigate } from 'react-router-dom';
import Logo from '../../assets/lazona.png';
import LogoMenu from '../../assets/lazona.png';
//import { useAuth} from '../../context/AuthProvider';
import AuthService from "../../api/ApiRest";
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../auth/authContext';
import { types } from '../../types/types';
import Container from 'react-bootstrap/Container';
import NavbarB from 'react-bootstrap/Navbar';



export const Navbar = () => {
    const navigate = useNavigate();
    //const { auth, setAuth } = useAuth();
    const {user, dispatch}=useContext(AuthContext);
    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

    const handleLogout =  () => {
        try {
            
            AuthService.logout();
            dispatch({type: types.logout});
            //setAuth({});
            navigate('/login',{replace:true});
        } catch (error) {
            console.log(error);
        }
    }
    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };
    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
        if (window.innerWidth <= 768) {
            setIsMenuVisible(true);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    /**
     * <div className="d-flex">
            {isMenuVisible && (
        <nav className="navbar navbar-dark bg-dark flex-column vh-100">
            <NavbarB className="bg-body-tertiary">
                <Container>
                    <NavbarB.Brand href="/">{user.nombres} {user.primer_apellido} {user.segundo_apellido}</NavbarB.Brand>
                </Container>
            </NavbarB>
            <br />
            <Link className="navbar-brand" to="/">
              <img src={Logo} style={{ width: 100 }} alt="Club la Zona" />
            </Link>
            
            <div className="navbar-nav flex-column mt-4">
                <NavLink
                    className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')}
                    to="/perfil"
                >
                    <i className='bi bi-person-fill'></i><span className='ms-3 d-none d-sm-inline'>Perfil</span>
                </NavLink>
                <NavLink
                    className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')}
                    to="/eventos"
                >
                    <i className='bi bi-calendar-check-fill'></i><span className='ms-2 d-none d-sm-inline'>Próximos Eventos</span>
                </NavLink>
                <NavLink
                    className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')}
                    to="/descuentos"
                >
                    <i className='bi bi-tags-fill'></i><span className='ms-2 d-none d-sm-inline'>Descuentos</span>
                </NavLink>
            </div>
            <div className="navbar-nav mt-auto">
                    
                <button
                    className="nav-item nav-link btn"
                    onClick={handleLogout}
                >
                    <i className='bi bi-person-circle'></i><span className='ms-2 d-none d-sm-inline'>Cerrar Sesión</span>
                    
                </button>
            </div>
        </nav>
        )}
        <div className="d-flex flex-column align-items-start">
            <button className="btn btn-dark mt-2" onClick={toggleMenu}>
                {isMenuVisible ? <i className="bi bi-chevron-left"></i> : <i className="bi bi-chevron-right"></i>}
            </button>
            {!isMenuVisible && !isSmallScreen &&  <div className="mt-2"></div>}
        </div>
    </div>
     */
    return (
        <div className="d-flex">
            {isMenuVisible && (
                <nav className={`navbar navbar-dark bg-dark flex-column vh-100 ${isSmallScreen ? 'navbar-small' : ''}`} style={{ width: isSmallScreen ? '80px' : '250px' }}>
                    <NavbarB>
                        <Container>
                            <NavbarB.Brand href="/">
                                <img src={LogoMenu} style={{ width: isSmallScreen ? '50px' : '100px' }} alt="Club la Zona" />
                            </NavbarB.Brand>
                        </Container>
                    </NavbarB>
                    <br />
                    <div className="navbar-nav flex-column mt-4">
                        <NavLink className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} to="/perfil">
                            <i className='bi bi-person-fill'></i><span className='ms-3 d-none d-sm-inline'>{isSmallScreen ? '' : 'Perfil'}</span>
                        </NavLink>
                        <NavLink className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} to="/eventos">
                            <i className='bi bi-calendar-check-fill'></i><span className='ms-2 d-none d-sm-inline'>{isSmallScreen ? '' : 'Próximos Eventos'}</span>
                        </NavLink>
                        <NavLink className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} to="/descuentos">
                            <i className='bi bi-tags-fill'></i><span className='ms-2 d-none d-sm-inline'>{isSmallScreen ? '' : 'Descuentos'}</span>
                        </NavLink>
                    </div>
                    <div className="navbar-nav mt-auto">
                        <button className="nav-item nav-link btn" onClick={handleLogout}>
                            <i className='bi bi-person-circle'></i><span className='ms-2 d-none d-sm-inline'>{isSmallScreen ? '' : 'Cerrar Sesión'}</span>
                        </button>
                    </div>
                </nav>
            )}
            <div className="d-flex flex-column align-items-start">
                <button className="btn btn-dark mt-2" onClick={toggleMenu}>
                    {isMenuVisible ? <i className="bi bi-chevron-left"></i> : <i className="bi bi-chevron-right"></i>}
                </button>
                {!isMenuVisible && !isSmallScreen && <div className="mt-2"></div>}
            </div>
        </div>
    )
}