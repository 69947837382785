import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Logo from '../../assets/lazona.png';
import QRCode from 'react-qr-code';
import { useContext } from 'react';
import { AuthContext } from '../../auth/authContext';
import AuthService from "../../api/ApiRest";
import '../styles/principal.css'
import { Card, Col, Row } from "react-bootstrap";

const Dashboard = () => {
const {user, dispatch}=useContext(AuthContext);
const [nombre,setNombre]=useState('');
const [rut, setRut] = useState('');
const [vigencia, setVigencia] = useState('');
const [correo, setCorreo] = useState('');
const [id, setId] = useState('');

useEffect(()=>{
    const participantesData=async()=>{

        const response= await AuthService.getUsuarioId(user.id);
        const options = { style: 'decimal', maximumFractionDigits: 2 }; // Opciones de formateo

        setNombre(response.data.body[0].nombres+' '+response.data.body[0].primer_apellido+' '+response.data.body[0].segundo_apellido);
        setRut(response.data.body[0].dv?response.data.body[0].run.toLocaleString('es-ES', options)+'-'+response.data.body[0].dv:response.data.body[0].run.toLocaleString('es-ES', options));
        setVigencia(response.data.body[0].vigencia.substring(0,10));
        setCorreo(response.data.body[0].correo_electronico);
        setId(response.data.body[0].id);
        
    }
    participantesData();

},[])
  return (
    <div className="App">
      <Container className="mt-2">
        <Row className="justify-content-md-center">
          <Col md="10">
            <Card className='tarjeta-perfil-socio'>
              <div className="custom-logo-container">
                <img src={Logo} alt="Club La Zona" className="custom-logo" />
              </div>
              <Card.Body>
                <Card.Text className="custom-presentation-card-body">
                    <span>NOMBRES: {nombre}</span>
                    <span>RUN: {rut}</span>
                </Card.Text>
                
                <QRCode value={`https://socios.clublazona.cl/socio/${btoa(id)}`}  size={90}/>
                
              </Card.Body>
              <Card.Footer className="socio-footer">
                
                    <span>VIGENCIA: {vigencia}</span>
               
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const estiloContenedor = {
  display: 'flex',
  justifyContent: 'center', // Centrar horizontalmente 
  alignItems: 'center',     // Centrar verticalmente
  height: '90vh',          // Ajustar altura al 100% de la ventana
};
const estiloImagen = {
  width:400,
  opacity: 0.2, // Valor entre 0 (transparente) y 1 (opaco)
};

export default Dashboard;
