import React from 'react';
import { Card, Button } from 'react-bootstrap';

const TarjetaEvento = ({ product }) => {
  return (
    <Card className='card-shadow' style={{ width: '14rem', margin: '1rem', overflow: 'hidden' }}>
        <div className="card-img-zoom-container">
            <Card.Img className="card-img-zoom" variant="top" src={product.image} />
        </div>
      
      <Card.Body>
            <div>
                <p className="evento-lugar">
                    <strong>{product.lugar}</strong> {product.tipo}
                </p> 
                <h3>
                    {product.evento}
                </h3> 
                <p className="evento-fecha">{product.fecha}</p>
            </div>
        <Button className='custom-button' variant="primary">Reservar</Button>
      </Card.Body>
    </Card>
  );
};

export default TarjetaEvento;