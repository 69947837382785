import React, { useRef, useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
//import Logo from "../../assets/cfc-unap/PNG/3000px/COLOR CORPORATIVO/CFC-UNAP-imagotipo-vertical(3000px).png";
import Logo from "../../assets/lazona_black.png";
import { useNavigate } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
//import {useAuth} from "../../context/AuthProvider"
import AuthService from "../../api/ApiRest";
import { types } from "../../types/types";
import { AuthContext } from "../../auth/authContext";
import '../../index.css'; // Importa tus estilos aquí

function Login() {
  const {dispatch} = useContext(AuthContext);
  //const {setAuth}= useAuth();

  const navigate = useNavigate();
  
  const userRef =useRef();
  const errRef = useRef();
  const [user,setUser]=useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg]=useState('');

  const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio);
  const [fondoStyle, setFondoStyle] = useState({});
  const [tarjetaStyle, setTarjetaStyle] = useState({});
  useEffect(() => {
    const updateZoomLevel = () => {
      const zoom = window.devicePixelRatio;
      setZoomLevel(zoom);

      const height = zoom >= 1 ? '120vh' : zoom >= 0.8 ? '140vh' :zoom <= 0.6 ? '110vh':'140vh';
      const width = zoom >= 1 ? '50vh' : zoom >= 0.8 ? '50vh' :zoom <= 0.6 ? '50vh':'90vh';

      const updatedFondo = {
        background: "url(../../assets/fondo.png),rgb(28, 35, 38)",
        margin: 0,
        padding: 0,
        height: height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
      setFondoStyle(updatedFondo);
      const updateTarjeta = {
        width: width,
        background:'black',
      };
      setTarjetaStyle(updateTarjeta);
    };
    window.addEventListener('resize', updateZoomLevel);
    updateZoomLevel(); // Initial check

    return () => {
      window.removeEventListener('resize', updateZoomLevel);
    };
  }, []);

  useEffect(()=>{
    userRef.current.focus();
  },[])
  useEffect(()=>{
    setErrMsg('');
  },[user,pwd])
  const handleSubmit = async (e)=>{
    e.preventDefault();
    try {
        const response=await AuthService.login(user,pwd);
        if(response.status===200){
            const accessToken=response.data.body;
            try {
            const tokenPayload = accessToken.split('.')[1];
            const decodedPayload = base64UrlDecode(tokenPayload);
            const payloadObj = JSON.parse(decodedPayload);

            const responseUser =await AuthService.getUsuarioId(payloadObj.id);
            const action={type:types.login,
                            payload:{ id:responseUser.data.body[0].id,
                                        name:user,
                                        nombres:responseUser.data.body[0].nombres,
                                        primer_apellido: responseUser.data.body[0].primer_apellido,
                                        segundo_apellido: responseUser.data.body[0].segundo_apellido,
                                        correo_electronico: responseUser.data.body[0].correo_electronico,
                                        token:accessToken}
                            }
                dispatch(action);

            } catch (error) {
            console.error('Error decoding the JWT:', error);
            }

            const lastPath=localStorage.getItem('lastPath')||'/';
                //setAuth({user,pwd,accessToken});
                //setUser('');
                //setPwd('');
                navigate(lastPath,{replace:true});
        }else{
            setErrMsg(response.body);
            console.log(errMsg)
        }
    } catch (error) {
        if(!error?.response){
            setErrMsg('Servidor no responde');
            console.log('Servidor no responde ')
        }else if(error.response?.status===500){
            setErrMsg('Datos erroneos');
            console.log('Datos erroneos ')
        }else if(error.response?.status===400){
            setErrMsg('Usuario y contraseña desconocidos');
            console.log('Usuario y contraseña desconocidos ')
        }else if(error.response?.status===401){
            setErrMsg('No autorizado');
            console.log('No autorizado ')
        }else{
            setErrMsg('Ha fallado');
            console.log('Ha fallado ')
        }
        console.log('error: '+errMsg)
        if (errRef.current) {
            errRef.current.focus();
        }
    }
    //setUser('');
    //setPwd('');
  }
  const base64UrlDecode = (base64Url) => {
    const padding = '='.repeat((4 - base64Url.length % 4) % 4);
    const base64 = (base64Url + padding).replace(/-/g, '+').replace(/_/g, '/');
    return atob(base64);
  };
  return (
    <div style={fondoStyle}>
      <Container style={contenedor}>
        <Card style={tarjetaStyle}>
          <Card.Img variant="top" src={Logo} style={logoEstilo}/>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Form.Group className="mb-3" controlId="usuario">
                    <FloatingLabel
                        controlId="usuario"
                        label="Usuario"
                        className="mb-3"
                    >
                        <Form.Control
                        type="text"
                        ref={userRef}
                        className="form-control"
                        onChange={(e)=>setUser(e.target.value)}
                        value={user}
                        placeholder="Usuario"
                        required
                        />
                    </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="mb-3" controlId="password">
                    <FloatingLabel
                        controlId="password"
                        label="Contraseña"
                        className="mb-3"
                    >
                        <Form.Control
                        type="password"
                        className="form-control"
                        onChange={(e)=>setPwd(e.target.value)}
                        value={pwd}
                        placeholder="Contraseña"
                        />
                    </FloatingLabel>
                    </Form.Group>
                </Row>
                <Row>
                    {errMsg && (
                    <Alert variant="danger">
                        Hubo un error:<p ref={errRef}>{errMsg}</p>
                    </Alert>
                    )}
                </Row>
                <Row>
                    <div className="d-flex text-white justify-content-between mb-4">
                    <Form.Check 
                        name="flexCheck"
                        value=""
                        id="flexCheckDefault"
                        label="Recordar"
                    />
                    </div>
                </Row>
                <Row>
                    <Col className="d-grid gap-2">
                    <Button className="custom-button" size="lg" type="submit">
                        Acceder
                    </Button>
                    </Col>
                </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
const contenedor = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
};
const logoEstilo = {
  display: 'block',
  margin: '0 auto',
  maxWidth: '550px',
  maxHeight: '550px',
};

export default Login;